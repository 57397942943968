import { AxiosRequestConfig } from "axios";
import { ConsoleLogger } from "../../utils/logger";

type ValueOf<T> = T[keyof T];
// BEWARE
// Due to https://github.com/Microsoft/TypeScript/issues/13195 and https://stackoverflow.com/questions/54489817/typescript-partialt-type-without-undefined
// AvailableParams has string | undefined
type AvailableParams = Partial<Record<ValueOf<typeof QUERY_PARAMS>, string>>;
const logger = new ConsoleLogger();

export type ServiceNames = keyof ReturnType<typeof apiBaseEndpoints>;

export interface QueryParamsResolver extends AxiosRequestConfig {
    query?: string;
    params?: AvailableParams;
}

export const apiBaseUrls = () => {
    let urlConfig: any = {};

    urlConfig = {
        BASE_API_URL: process.env.REACT_APP_API_SERVER_URL,
        CAREX_API_URL: process.env.REACT_CAREX_API_SERVER_URL,
        api: "http://192.168.1.23:3000",
        LEGACY_API_URL: process.env.REACT_APP_LEGACY_API_URL,
        SCHEDULER_API_URL: process.env.REACT_APP_SCHEDULER_API_URL,
        CEX_API_URL: process.env.REACT_APP_CEX_API_URL,
        SIMPATRA_API: process.env.REACT_APP_SIMPATRA_API,
        CEX_CARE_API: process.env.REACT_APP_CEX_CARE_API,
        CEX_HOME_API: process.env.REACT_APP_CEX_HOME_API,
        CEX_AUTH_RS: process.env.REACT_APP_AUTH_RS_URL,
        CEX_EMR_API: process.env.REACT_APP_EMR_RS_API,
    };
    logger.log("cex__auth", process.env.REACT_APP_AUTH_RS_URL);
    return {
        LEGACY_API_URL: urlConfig.LEGACY_API_URL || urlConfig.BASE_API_URL + ":3000",
        SCHEDULER_API_URL: urlConfig.SCHEDULER_API_URL || urlConfig.BASE_API_URL + ":3011",
        CAREX_API_URL: urlConfig.CEX_API_URL || urlConfig.BASE_API_URL + ":3010",
        CEX_API_URL: urlConfig.CEX_API_URL || urlConfig.BASE_API_URL + ":3010",
        SIMPATRA_API: urlConfig.SIMPATRA_API || urlConfig.BASE_API_URL + ":3012/laborders",
        CEX_CARE_API: urlConfig.CEX_CARE_API || urlConfig.BASE_API_URL + ":3110",
        CEX_HOME_API: urlConfig.CEX_HOME_API || urlConfig.BASE_API_URL + ":3100",
        CEX_AUTH_RS: urlConfig.CEX_AUTH_RS || urlConfig.BASE_API_URL + ":3030",
        CEX_EMR_API: urlConfig.CEX_EMR_API || urlConfig.BASE_API_URL + ":3060",
    };
};

export const resolveUrl = (serviceName: ServiceNames, { query = "", params = {} }: QueryParamsResolver): string => {
    const serviceMapping = apiBaseEndpoints();
    const path = serviceMapping[serviceName];

    params = {
        ...params,
        ...apiBaseUrls(),
    };
    const logger = new ConsoleLogger();
    logger.log("cexparams", params);
    const url = Object.keys(params).reduce((acc, param) => {
        return acc.replace(param, (params as any)[param]);
    }, path);
    logger.log("params", query ? `${url}?${query}` : url);
    logger.log("query", query);
    logger.log("params", params);

    return query ? `${url}?${query}` : url;
};

export enum QUERY_PARAMS {
    CEX_AUTH_RS = ":CEX_AUTH_RS",
    idPost = ":idPost",
    offset = ":offset",
    limit = ":limit",
    id = ":id",
    signature = ":signature",
    otp = ":otp",
    token = ":token",
}

export const apiBaseEndpoints = () => {
    const postSignUp = "CEX_AUTH_RS/v1/cex/authrs/auth/createAccount";
    const postSignIn = "CEX_AUTH_RS/v1/cex/authrs/auth/signIn";
    const postForgotPassword = "CEX_AUTH_RS/v1/cex/authrs/auth/forgotPassword";
    const postResetPassword = "CEX_AUTH_RS/v1/cex/authrs/auth/resetPassword";
    const getPreCreateAccount = "CEX_AUTH_RS/v1/cex/authrs/auth/precreatedAccount?token=:token";
    const postPreCreateAccount = "CEX_AUTH_RS/v1/cex/authrs/auth/precreatedAccount?token=:token";
    //WebPresenceSection
    //Reviews
    const webPresenceReviews = "CEX_HOME_API/v1/providers/web-review/reviews";
    const webPresenceGetReviews = "CEX_HOME_API/v1/providers/web-review/reviews?offset=:offset&limit=:limit";
    const webPresenceGetReviewsRatings = "CEX_HOME_API/v1/providers/web-review/reviewRatings";
    const webPresenceReviewAccounts = "CEX_HOME_API/v1/providers/web-review/accounts";
    //posts
    const webPresencePosts = "CEX_HOME_API/v1/providers/web-presence/posts";
    const webPresenceGetPosts = "CEX_HOME_API/v1/providers/web-presence/posts?offset=:offset&limit=:limit";
    const updateWebPresencePosts = "CEX_HOME_API/v1/providers/web-presence/posts/:idPost";
    const webPresencePostAccounts = "CEX_HOME_API/v1/providers/web-presence/accounts";

    //generateAIpost
    const webPresenceAIPosts = " https://rk5h1smeeh.execute-api.us-east-1.amazonaws.com/dev/v1/cex/next-gen/ai";

    //validation
    const downloadCCDA = "CEX_EMR_API/v1/cex/patient/download/CCDAXML";
    const generate2FA = "CEX_AUTH_RS/v1/cex/authrs/auth/generate-code";
    const verify2FA = "CEX_AUTH_RS/v1/cex/authrs/auth/verify-code";
    return {
        generate2FA,
        verify2FA,
        postSignUp,
        getPreCreateAccount,
        postPreCreateAccount,
        postSignIn,
        postResetPassword,
        postForgotPassword,
        webPresenceAIPosts,
        webPresenceGetPosts,
        webPresenceGetReviews,
        webPresenceGetReviewsRatings,
        webPresencePostAccounts,
        webPresencePosts,
        webPresenceReviewAccounts,
        webPresenceReviews,
        updateWebPresencePosts,
        downloadCCDA,
    };
};
