import { doGet, doPost } from "./gateway/carexGateway";
import { CancelToken } from "axios";
import { IPostForgotPassword, IPostResetPassword, IPostSignIn, IPostSignUp } from "../redux/auth/auth.types";
import { ConsoleLogger } from "../utils/logger";
const AuthService = {
    postSignUp: async (payload: IPostSignUp, cancelToken?: CancelToken) => {
        try {
            const res = await doPost<IPostSignUp[], IPostSignUp>("postSignUp", {
                data: payload,
                cancelToken,
            });
            const logger = new ConsoleLogger();
            logger.log("signUp res", res);
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.reject(err);
        }
    },
    postSignIn: async (payload: IPostSignIn, cancelToken?: CancelToken) => {
        const logger = new ConsoleLogger();
        logger.log("auth.api", payload);
        try {
            const res = await doPost<IPostSignIn[], IPostSignIn>("postSignIn", {
                data: payload,
                cancelToken,
            });
            const logger = new ConsoleLogger();
            logger.log("res", res);
            return Promise.resolve(res.data);
        } catch (err) {
            logger.log("res", err);
            return Promise.reject(err);
        }
    },
    postForgotPassword: async (payload: IPostForgotPassword, cancelToken?: CancelToken) => {
        try {
            const res = await doPost<IPostForgotPassword[], IPostForgotPassword>("postForgotPassword", {
                data: payload,
                cancelToken,
            });
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.reject(err);
        }
    },
    postResetPassword: async (payload: IPostResetPassword, cancelToken?: CancelToken) => {
        try {
            const res = await doPost<IPostResetPassword[], IPostResetPassword>("postResetPassword", {
                data: payload,
                cancelToken,
            });
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.reject(err);
        }
    },
    getPreCreateAccount: async (payload: any, cancelToken?: CancelToken) => {
        try {
            const res = await doGet<any>("getPreCreateAccount", {
                params: { ":token": payload.token },
                cancelToken,
            });
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.reject(err);
        }
    },
    postPreCreateAccount: async (payload: any, cancelToken?: CancelToken) => {
        try {
            const res = await doGet<any>("postPreCreateAccount", {
                params: { ":token": payload.token },
                data: payload,
                cancelToken,
            });
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.reject(err);
        }
    },
    generate2FA: async (payload: any, cancelToken?: CancelToken) => {
        try {
            const res = await doPost<any[], any>("generate2FA", {
                data: payload,
                cancelToken,
            });
            const logger = new ConsoleLogger();
            logger.log("genrate2FA res", res);
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.reject(err);
        }
    },
    verify2FA: async (payload: any, cancelToken?: CancelToken) => {
        try {
            const res = await doPost<any[], any>("verify2FA", {
                data: payload,
                cancelToken,
            });
            const logger = new ConsoleLogger();
            logger.log("verify2FA res", res);
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.reject(err);
        }
    },
};

export default AuthService;
